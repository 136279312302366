<template>
  <b-navbar transparent>
    <template slot="start" v-if="user">
      <b-navbar-item
        v-for="(item, i) in items.filter((i) => !i.hidden)"
        :key="i"
        tag="router-link"
        :to="{ name: item.link }"
      >
        {{ item.text }}
      </b-navbar-item>
    </template>
    <template slot="end">
      <div class="nav-end"></div>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    items() {
      return [
        {
          text: 'Accueil',
          link: 'Home',
          hidden: this.user.is_user,
        },
        {
          text: 'Les clients',
          link: 'ClientList',
          // hidden: this.user.is_user,
        },
        {
          text: 'Les équipements',
          link: 'EquipmentList',
          // hidden: this.user.is_user,
        },
        {
          text: 'Statistiques',
          link: 'Statistics',
          hidden: this.user.is_user,
        },
        {
          text: 'Les utilisateurs',
          link: 'UserList',
          hidden: this.user.is_user,
        },
      ].filter((item) => {
        /**
         * Pris en compte des permissions au niveau des routes
         */
        let { route } = this.$router.resolve({ name: item.link })

        for (let r of route.matched) {
          if (r.meta.deny && r.meta.deny(this.user)) {
            return false
          }
        }

        return true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-end {
  display: flex;
  align-items: center;
}
.navbar {
  background: $gray;

  @include tablet {
    height: 70px;
  }
}
.navbar-item {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  margin-inline: 0.65rem;
  border-bottom: solid 1px transparent;
  transition: all 0.2s ease-out;
  border-color: transparent;
  border-width: 8px;

  &:hover,
  &.router-link-active {
    background: transparent;
    border-color: red;
  }

  @include desktop {
    color: $white;
    &:focus {
      color: $white;
    }
    &:hover {
      color: $grey-light;
    }
  }
}
/deep/ .navbar-burger {
  color: $white;
}
.datetime {
  color: $white;
  font-style: italic;
  display: flex;
  padding: 1rem;
  & div {
    margin: auto;
  }
}
</style>

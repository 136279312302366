<template>
  <div class="container is-fluid">
    <h1>{{ $route.meta.title }}</h1>

    <base-data-table
      model="clients"
      ref="datatable"
      :columns="columns"
      :filter="filter"
      :disableImport="!user.is_super_admin"
      disable-search
      :disableCreate="user.is_commercial"
    >
      <span slot="import-label">Importer des clients</span>
      <!-- <span slot="import-label"> Voir les archives </span> -->
      <span slot="create-label">
        <span>Nouveau client</span>
      </span>

      <template v-slot:field-action="props">
        <base-show-button :id="props.row.id"></base-show-button>
      </template>

      <template v-slot:field-status="props">
        <span :class="props.row.status" class="tag">
          {{ statuses[props.row.status] }}
        </span>
      </template>

      <template v-slot:field-created_at="props">
        {{ $formatDate(props.row.created_at, false) }}
      </template>

      <template v-slot:field-is_canceled="props">
        <base-boolean :value="props.row.is_canceled"></base-boolean>
      </template>

      <template v-slot:field-equipments="props">
        {{ props.row.equipments_readable }}
      </template>

      <template v-slot:field-type="props">
        {{ props.row.type }}
      </template>

      <template v-slot:field-csp="props">
        {{ contracts[props.row.csp] }}
      </template>

      <template v-slot:row-actions="props">
        <base-show-button
          v-if="!user.is_admin"
          :id="props.row.id"
        ></base-show-button>
      </template>
    </base-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      statuses: (state) => state.enums.statuses,
      equipments: (state) => state.enums.equipments,
      contracts: (state) => state.enums.contracts,
    }),
    columns() {
      return [
        {
          field: 'created_at',
          label: 'Date de création',
          type: 'date',
          searchable: true,
          sortable: true,
        },
        {
          field: 'client_number',
          label: 'N° client',
          searchable: true,
          sortable: true,
        },
        {
          field: 'name',
          label: 'Nom - Prénom client',
          searchable: true,
          sortable: true,
        },
        {
          field: 'sav',
          label: 'En SAV',
          searchable: true,
          sortable: false,
          type: 'select',
          options: ['Non', 'Oui'],
        },
        {
          field: 'equipments',
          label: 'Liste des services',
          searchable: true,
          sortable: false,
          type: 'select',
          options: this.equipments,
        },
        {
          field: 'phone',
          label: 'Téléphone',
          searchable: true,
          sortable: true,
        },
        {
          field: 'email',
          label: 'Email',
          searchable: true,
          sortable: true,
        },
        {
          field: 'postcode_city',
          label: 'CP - Ville',
          searchable: true,
          sortable: true,
        },
        {
          field: 'department',
          label: 'DPT',
          searchable: true,
          sortable: true,
        },
        {
          field: 'is_canceled',
          label: 'Résilié',
          searchable: true,
          sortable: true,
          type: 'select',
          options: {
            true: 'Oui',
            false: 'Non',
          },
        },
        {
          field: 'csp',
          label: 'CSP',
          searchable: true,
          sortable: false,
          type: 'select',
          options: this.contracts,
        },
        {
          field: 'action',
          label: '',
          hidden: !this.user.is_admin,
        },
      ]
    },
    filter() {
      return { ...this.$route.meta.filter, inactive: this.inactive }
    },
  },
  methods: {
    loadAsyncData() {
      this.$refs.datatable.loadAsyncData()
    },
  },
}
</script>

<template>
    <div>
      <p class="mb-2" style="font-weight:bold">
        Le {{$formatDate(comment.updated_at, 'dd/MM/yyyy HH:mm')}} par {{comment.user.name}} 
      </p>
      <div class="is-flex" style="align-items:center">
        <div>{{comment.content}}</div>
          <base-delete-button
          class="ml-4"
          v-if="
            this.user.is_super_admin || (this.user.is_admin && this.user.is_user)
            " 
            :id="comment.id"
            model="comments"
            confirm-title="Supprimer un commentaire"
            confirm-message="Etes-vous sûr de vouloir supprimer ce commentaire ? Cette action est irréversible !"
            success-message="Commentaire supprimé avec succès !"
            @deleted="reload()"
          />
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from './form.vue'
export default {
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      comm: (state) => state.comm,
    }),
  },
  data() {
    return {
      edition: false,
    }
  },
  components: { Form },
  methods: {
    reload() {
      this.$emit('refresh')
    },
   }
}
</script>

<style lang="scss" scoped>
.sav-item-card {
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  .card-edit {
    position: absolute;
    top: 0.75rem;
    right: 0;
  }
}
</style>

<template>
  <div
    v-if="
      equipment &&
      equipment.status !== 'ABANDONMENT' &&
      equipment.status !== 'CANCELED' &&
      equipment.status !== 'NEW'
    "
    class="card p-5"
  >
    <div class="card-header">
      <div class="card-header_title">Devis</div>
      <div class="card-header_btn">
        <b-button
          v-if="
            this.user.is_super_admin ||
            (this.user.is_admin && this.user.is_user)
          "
          icon-left="pencil-outline"
          @click="edition = !edition"
        ></b-button>
      </div>
    </div>
    <base-model-form
      v-if="equipment.status === 'QUOTATION_WAIT' || edition"
      model="clients"
      v-model="form"
      model-name="Client"
      :custom-url="`/api/clients/${client.id}/equipment/${$route.query.equipment}`"
      custom-method="patch"
      :saving.sync="saving"
      :replace="replace"
      updated
    >
      <div class="mt-4">
        <base-field label="Accepté*" name="is_quotation_accepted" horizontal>
          <b-radio-button
            v-for="(label, value) in {
              Oui: 1,
              Non: 0,
            }"
            :key="value"
            v-model="form.is_quotation_accepted"
            :native-value="label"
          >
            {{ value }}
          </b-radio-button>
        </base-field>
        <div v-if="form.is_quotation_accepted">
          <b-field label="Signé le" label-for="quotation_at">
            <base-datepicker
              id="quotation_at"
              icon="calendar-today"
              trap-focus
              v-model="form.quotation_at"
              @input="period = null"
              :with-time="false"
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
          <b-field label="Reçu le" label-for="quotation_reception_at">
            <base-datepicker
              id="quotation_reception_at"
              icon="calendar-today"
              trap-focus
              v-model="form.quotation_reception_at"
              @input="period = null"
              :with-time="false"
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
          <b-field label="Numéro de devis" name="quotation_number">
            <b-input
              id="quotation_number"
              v-model="form.quotation_number"
              type="text"
            ></b-input>
          </b-field>
           <b-field label="Tag" name="quotation_tag">
            <b-input
              id="quotation_tag"
              v-model="form.quotation_tag"
              type="text"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="is-flex mt-5">
        <b-button
          tag="router-link"
          class="mx-auto"
          :to="{ name: 'ClientList' }"
        >
          Annuler
        </b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="form.is_quotation_accepted === null"
          :loading="saving"
          class="mx-auto"
        >
          Valider
        </b-button>
      </div>
    </base-model-form>
    <div v-else class="mt-3">
      <div
        v-if="
          equipment.quotation_at != undefined && equipment.quotation_at !== ''
        "
      >
        Signé le {{ $formatDate(equipment.quotation_at, false) }}
      </div>
      <div class="mt-2"
        v-if="
          equipment.quotation_reception_at != undefined && equipment.quotation_reception_at !== ''
        "
      >
        Reçu le {{ $formatDate(equipment.quotation_reception_at, false) }}
      </div>
      <div class="mt-2"
        v-if="
          equipment.quotation_number != undefined && equipment.quotation_number !== ''
        "
      >
        Numéro de devis : {{ equipment.quotation_number}}
      </div>
      <div class="mt-2"
        v-if="
          equipment.quotation_tag != undefined && equipment.quotation_tag !== ''
        "
      >
        Tag : {{ equipment.quotation_tag}}
      </div>
      <div class="mt-2" v-else>Signé</div>
      <!-- <div
        v-if="equipment.client_ref != undefined && equipment.client_ref !== ''"
        class="mt-3"
      >
        Réf client OAV : {{ equipment.client_ref }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isModalAddEquipmentActive: false,
      // Note : form ne définit pas de statut. Le statut est modifié côté serveur en fonction de is_quotation_accepted.
      form: {
        is_quotation_accepted: null,
        quotation_at: null,
        quotation_reception_at: null,
        quotation_number: null,
        quotation_tag: null,
        client_ref: null,
      },
      saving: false,
      edition: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      client: (state) => state.client,
      equipment: (state) => state.equipment,
    }),
  },
  mounted() {
    this.refresh()
  },
  watch: {
    equipment(newValue) {
      this.refresh()
    },
  },
  methods: {
    refresh() {
      // Initialisation du formulaire this.form en fonction de this.equipment
      if (this.equipment && this.equipment.status !== 'QUOTATION_WAIT') {
        this.form.is_quotation_accepted =
          this.equipment.status === 'TO_INSTALL' ? 1 : 0
        this.form.quotation_at = this.equipment.quotation_at
        this.form.quotation_reception_at = this.equipment.quotation_reception_at
        this.form.quotation_number = this.equipment.quotation_number
        this.form.quotation_tag = this.equipment.quotation_tag
        this.form.client_ref = this.equipment.client_ref
        this.form.invoicing = this.equipment.invoicing
      } else {
        this.form.is_quotation_accepted = null
        this.form.quotation_at = null
        this.form.quotation_reception_at = null
        this.form.quotation_number = null
        this.form.quotation_tag = null
        this.form.invoicing = 0
      }
    },
    replace() {
      this.$emit('refresh')
      this.edition = false
    },
  },
}
</script>

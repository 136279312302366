<template>
  <base-model-form
    model="clients"
    v-model="form"
    model-name="Client"
    redirect="model"
    :default="model"
    :saving.sync="saving"
  >
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <base-field label="Civilité*" name="civility" horizontal>
              <b-checkbox-button
               v-for="(civility, value) in civilities"
                :key="value"
                v-model="form.civility"
                :native-value="value"
              >
                <span v-if="civility == 'Mr'">Monsieur</span>
                <span v-if="civility == 'Mme'">Madame</span>
              </b-checkbox-button>
            </base-field>
          </div>
          <div class="column">
            <base-field label="CSP" name="csp" horizontal>
              <b-select
                id="csp"
                placeholder="Sélectionner un CSP"
                expanded
                v-model="form.csp"
              >
                <option
                  v-for="(contract, contractkey) in contracts"
                  :value="contractkey"
                  :key="contractkey"
                >
                  {{ contract }}
                </option>
              </b-select>
            </base-field>
          </div>
        </div>
        <div v-if="form.csp === 'COMMUNITY'" class="columns">
          <div class="column large">
            <base-field label="Site" name="entry_site" horizontal>
              <b-input
                id="entry_site"
                v-model="form.entry_site"
                expanded
                :disabled="form.csp !== 'COMMUNITY'"
              ></b-input>
            </base-field>
          </div>
        </div>
        <div v-if="form.csp !== 'PRIVATE'" class="columns">
          <div class="column large">
            <base-field label="Raison sociale" name="social_reason" horizontal>
              <b-input
                id="social_reason"
                v-model="form.social_reason"
                expanded
                :disabled="form.csp === 'PRIVATE'"
              ></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field
              label="Nom*"
              name="lastname"
              horizontal
              :mask="{ mask: 'A*' }"
            >
              <b-input id="lastname" v-model="form.lastname" required></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field
              label="Prénom*"
              name="firstname"
              horizontal
              :mask="{ mask: 'Aa*' }"
            >
              <b-input
                id="firstname"
                v-model="form.firstname"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column large">
            <base-field label="Adresse*" name="address" horizontal>
              <b-input id="address" v-model="form.address" required></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field
              label="CP Ville*"
              name="postcode_city"
              horizontal
              :message="
                cityValidate
                  ? 'Le code postal vous donnera la ville si vous attendez quelques secondes.'
                  : ''
              "
            >
              <base-place
                id="postcode_city"
                v-model="form.postcode_city"
                required
              ></base-place>
            </base-field>
          </div>
          <div class="column">
            <base-field label="IDGRC" name="idgrc" horizontal>
              <b-input id="idgrc" v-model="form.idgrc"></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field
              label="Téléphone*"
              name="phone"
              :mask="{
                mask: '0Z ## ## ## ##',
                tokens: { Z: { pattern: /^[1-9]\d*$/ } },
              }"
              horizontal
            >
              <b-input id="phone" v-model="form.phone" required></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Email*" name="email" horizontal>
              <b-input
                id="email"
                type="email"
                v-model="form.email"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
        <div v-if="user.is_admin || user.is_super_admin || user.is_user" class="columns">
          <div class="column">
            <base-field label="Apporteur" name="bringer_id" horizontal>
              <base-autocomplete
                id="bringer_id"
                v-model="form.bringer_id"
                model="users"
                placeholder="Email"
                :text="(model) => model.email"
                :filter="{ role: 'BRINGER' }"
                required
              >
              </base-autocomplete>
            </base-field>
          </div>
          <div class="column">
            <base-field
              label="Numéro client"
              name="client_number"
              horizontal
            >
              <b-input id="client_number" v-model="form.client_number" type="number"></b-input>
            </base-field>
          </div>
        </div>
        <div v-if="user.is_admin || user.is_super_admin || user.is_user" class="columns">
          <div class="column">
            <b-field label="Par quel moyen avez-vous connu l'apporteur ?" label-for="type">
              <b-select
                id="bringer_know"
                expanded
                v-model="form.bringer_know"
                placeholder="Sélectionner une option"
              >
                <option
                  v-for="(name, value) in bringerKnows"
                  :value="value"
                  :key="value"
                >
                  {{ name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="form.bringer_know == 'parrainer'">
          <div class="column">
            <base-field label="Nom du parrain" name="parrainer_name">
              <b-input id="parrainer_name" v-model="form.parrainer_name"></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns" v-if="form.bringer_know == 'other'">
          <div class="column">
            <base-field label="Précisez" name="bringer_know_other">
              <b-input id="bringer_know_other" v-model="form.bringer_know_other"></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns" v-if="form.bringer_know == 'recovery'">
          <div class="column">
            <base-field label="Nom de l'ancien client" name="recovery_client">
              <b-input id="recovery_client" v-model="form.recovery_client"></b-input>
            </base-field>
          </div>
        </div>
        <div v-if="!this.$route.path.includes('edit')" class="columns">
          <div class="column large wrap">
            <base-field label="Équipements*" name="equipments" horizontal>
              <b-checkbox-button
                v-for="(product, value) in equipments"
                :key="value"
                v-model="form.equipments"
                :native-value="value"
              >
                {{ product }}
              </b-checkbox-button>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Commentaire" name="comment">
              <b-input id="comment" v-model="form.comment" type="textarea"></b-input>
            </base-field>
          </div>
        </div>
      </div>
      <div class="submit-button">
        <div class="double-button">
          <back-button />
          <b-button
            native-type="submit"
            type="is-primary"
            :disabled="cityValidate"
            :loading="saving"
          >
            <span v-if="model">Mettre à jour</span>
            <span v-else>Valider</span>
          </b-button>
        </div>
      </div>
    </div>
  </base-model-form>
</template>

<script>
import BackButton from '@/components/base/buttons/BackButton.vue'
import { mapState } from 'vuex'

export default {
  components: { BackButton },
  props: ['model'],
  data() {
    return {
      form: {
        idgrc: null,
        client_number: null,
        civility: [],
        csp: null,
        social_reason: null,
        lastname: null,
        firstname: null,
        address: null,
        postcode_city: null,
        phone: null,
        email: null,
        comment: null,
        entry_site: null,
        bringer_id: null,
        bringer_know: null,
        bringer_know_other: null,
        parrainer_name: null,
        recovery_client: null,
        equipments: [],
        status: null,
      },
      formFake: {
        idgrc: '12345',
        client_number: '45678',
        civility: ['MAN'],
        csp: 'PRIVATE',
        entry_site: 'site',
        social_reason: 'social reason',
        lastname: 'LASTNAME',
        firstname: 'firstname',
        address: 'address',
        postcode_city: '35000 - Rennes',
        phone: '06 00 00 00 00',
        email: 'user@mail.com',
        bringer_id: 3,
        bringer_know: 'gan',
        equipments: ['MONITORING_VIDEO'],
        status: 'NEW',
      },
      saving: false,
    }
  },
  mounted() {
    if (!this.$route.path.includes('edit')) {
      if (process.env.NODE_ENV === 'development') {
        this.form = this.formFake
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      civilities: (state) => state.enums.civilities,
      contracts: (state) => state.enums.contracts,
      equipments: (state) => state.enums.equipments,
      bringerKnows: (state) => state.enums.bringerKnows

    }),
    cityValidate() {
      return this.form.postcode_city && this.form.postcode_city.length < 6
    },
  },
}
</script>

<style lang="scss" scoped>
.large {
  /deep/ .field-body {
    flex-grow: 11.5;
  }
}
.wrap {
  /deep/ .field-body {
    flex-wrap: wrap;
  }
  /deep/ .field {
    margin: 0.25rem;
    flex-grow: unset !important;
  }
}
</style>

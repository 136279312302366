<template>
  <div class="clients-sav-item-form">
    <base-model-form
      model="savItem"
      v-model="form"
      model-name="SAV"
      :custom-url="url"
      :saving.sync="saving"
      :replace="replace"
      :custom-method="method"
      :updated="edition"
    >
      <base-field label="" name="level" horizontal class="mt-2 btn-levels">
        <b-radio-button
          v-for="level in levels"
          :key="level.number"
          v-model="form.level"
          :native-value="level.number"
        >
          {{ level.label }}
        </b-radio-button>
      </base-field>
      <div v-if="form.level">
        <div class="columns is-multiline">
          <b-field
            v-if="form.level === 2"
            class="column is-6-desktop is-12-mobile"
            label="Intervention"
            label-for="intervention_at"
          >
            <base-datepicker
              id="intervention_at"
              icon="calendar-today"
              trap-focus
              v-model="form.intervention_at"
              @input="period = null"
              position="is-bottom-right"
              with-time
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
          <b-field
            v-if="form.level >= 3"
            class="column is-6-desktop is-12-mobile"
            label="R1"
            label-for="r1_at"
          >
            <base-datepicker
              id="r1_at"
              icon="calendar-today"
              trap-focus
              v-model="form.r1_at"
              @input="period = null"
              position="is-bottom-right"
              with-time
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
          <b-field
            v-if="form.level >= 3"
            class="column is-6-desktop is-12-mobile"
            label="R2"
            label-for="r2_at"
          >
            <base-datepicker
              id="r2_at"
              icon="calendar-today"
              trap-focus
              v-model="form.r2_at"
              @input="period = null"
              position="is-bottom-right"
              with-time
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
          <base-field
            v-if="form.level >= 3"
            class="column is-12-desktop is-12-mobile"
            label="Commercial"
            name="commercial_id"
          >
            <base-autocomplete
              id="commercial_id"
              v-model="form.commercial_id"
              model="users"
              placeholder="Email"
              :text="(model) => model.email"
              :filter="{ role: 'COMMERCIAL' }"
              required
            >
            </base-autocomplete>
          </base-field>
        </div>
      </div>
      <base-field
        v-if="form.level >= 1"
        class="mt-4"
        label="Commentaire"
        name="comment"
      >
        <b-input
          id="comment"
          v-model="form.comment"
          expanded
          type="textarea"
        ></b-input>
      </base-field>
      <div class="is-flex mt-2">
        <div class="ml-auto">
          <b-button native-type="button" @click="replace()"> Annuler </b-button>
          <b-button
            native-type="submit"
            class="ml-2"
            type="is-primary"
            :loading="saving"
          >
            Valider
          </b-button>
        </div>
      </div>
    </base-model-form>
  </div>
</template>

<script>
export default {
  props: ['model', 'edition'],
  data() {
    return {
      form: {
        level: null,
        comment: null,
        intervention_at: null,
        r1_at: null,
        r2_at: null,
      },
      saving: false,
      levels: [
        {
          number: 1,
          label: 'Niveau 1',
        },
        {
          number: 2,
          label: 'Niveau 2',
        },
        {
          number: 3,
          label: 'Niveau 3',
        },
      ],
      url: null,
      method: 'post',
    }
  },
  mounted() {
    this.url = `/api/equipments/${this.$route.query.equipment}/sav-item`
    if ( this.model!=undefined ) {
      this.form = this.model
      this.url += `/${this.model.id}`
      this.method = 'patch'
    }
  },
  methods: {
    replace() {
      this.$emit('refresh')
      this.$emit('validate')
    },
  },
}
</script>

<style lang="scss">
.clients-sav-item-form {
  .btn-levels {
    .field-label {
      margin:0;
      display: none;
    }
    margin-bottom: 1rem !important;
  }
  .field:not(:last-child) {
    margin-bottom: 0;
  }
  .column {
    padding: 0.37rem 0.75rem;
  }
}
</style>

<template>
  <div class="card p-5">
    <div class="card-header">
      <div class="card-header_title">Découverte client</div>
      <div class="card-header_btn">
        <b-button
          v-if="
            this.user.is_super_admin ||
            (this.user.is_admin && this.user.is_user) || this.user.is_commercial
          "
          icon-left="pencil-outline"
          @click="edition = !edition"
        ></b-button>
      </div>
    </div>
    <base-model-form
      v-if="equipment.status === 'NEW' || edition"
      model="clients"
      v-model="form"
      model-name="Client"
      :custom-url="`/api/clients/${client.id}/equipment/${$route.query.equipment}`"
      custom-method="patch"
      :saving.sync="saving"
      :replace="replace"
      updated
    >
      <div class="mt-4">
        <base-field label="Eligible*" name="is_eligible" horizontal>
          <b-radio-button
            v-for="(label, value) in {
              Oui: 1,
              Non: 0,
            }"
            :key="value"
            v-model="form.is_eligible"
            :native-value="label"
          >
            {{ value }}
          </b-radio-button>
        </base-field>
        <div v-if="form.is_eligible">
          <base-field label="Commercial*" name="commercial_id">
            <base-autocomplete
              id="commercial_id"
              v-model="form.commercial_id"
              model="users"
              placeholder="Email"
              :text="(model) => model.email"
              :filter="{ role: 'COMMERCIAL' }"
              required
            >
            </base-autocomplete>
          </base-field>
          <b-field label="R1" label-for="r1_at">
            <base-datepicker
              id="r1_at"
              icon="calendar-today"
              trap-focus
              v-model="form.r1_at"
              @input="period = null"
              with-time
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
          <b-field label="R2" label-for="r2_at">
            <base-datepicker
              id="r2_at"
              icon="calendar-today"
              trap-focus
              v-model="form.r2_at"
              @input="period = null"
              with-time
              :force-mobile="true"
            ></base-datepicker>
          </b-field>
        </div>
      </div>
      <div class="is-flex mt-5">
        <b-button
          tag="router-link"
          class="mx-auto"
          :to="{ name: 'ClientList' }"
        >
          Annuler
        </b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="form.is_eligible == null"
          :loading="saving"
          class="mx-auto"
        >
          Valider
        </b-button>
      </div>
    </base-model-form>
    <div v-else-if="equipment.status === 'ABANDONMENT'  || equipment.status === 'INSTALLATION_ABANDON'" class="mt-3">
      Abandon le
      {{
        $formatDate(
          equipment.status_updated_at[equipment.status],
          'dd/MM/yyyy'
        )
      }}.
    </div>
    <div v-else-if="equipment.status === 'CANCELED'" class="mt-3">
      Résilié le
      {{
        $formatDate(
          equipment.status_updated_at[equipment.status],
          'dd/MM/yyyy'
        )
      }}.
    </div>
    <div v-else class="mt-3">
      <ul>
        <li>
          <div>
            1er RDV : {{ $formatDate(equipment.r1_at, 'dd/MM/yyyy hh:mm') }}
          </div>
          <AddEvent
            :client="client.id"
            :equipment="equipment.id"
            ics="r1"
          />
        </li>
        <li class="mt-2" v-if="equipment.r2_at!=undefined">
          <div>
            2ème RDV : {{ $formatDate(equipment.r2_at, 'dd/MM/yyyy hh:mm') }}
          </div>
          <AddEvent
            :client="client.id"
            :equipment="equipment.id"
            ics="r2"
          />
        </li>
      </ul>
      <div v-if="equipment.commercial" class="mt-3">
        Avec {{ equipment.commercial.first_name }}
        {{ equipment.commercial.last_name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddEvent from './add-event.vue'

export default {
  data() {
    return {
      isModalAddEquipmentActive: false,
      // Note : form ne définit pas de statut. Le statut est modifié côté serveur en fonction de is_eligible.
      form: {
        is_eligible: null,
        commercial_id: null,
        r1_at: null,
        r2_at: null,
      },
      saving: false,
      edition: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      client: (state) => state.client,
      equipment: (state) => state.equipment,
    }),
  },
  watch: {
    equipment(newValue) {
      this.refresh()
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      // Initialisation du formulaire this.form en fonction de this.equipment
      if (this.equipment && this.equipment.status !== this.$workflow.NEW) {
        this.form.is_eligible =
          this.equipment.status !== this.$workflow.ABANDONMENT &&
          this.equipment.status !== this.$workflow.CANCELED
            ? 1
            : 0
        this.form.commercial_id = this.equipment.commercial_id
        this.form.r1_at = this.equipment.r1_at
        this.form.r2_at = this.equipment.r2_at
      } else {
        this.form.is_eligible = null
        this.form.commercial_id = null
        this.form.r1_at = null
        this.form.r2_at = null
      }
    },
    replace() {
      this.$emit('refresh')
      this.edition = false
    },
  },
  components: { AddEvent },
}
</script>

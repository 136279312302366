<template>
  <div>
    <div class="is-flex">
      <span class="button ml-auto" @click="openModal()">
       Ajouter un document
      </span>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <form model="document" class="modal-card-body" @submit.prevent="submit">
          <h2 style="text-align:center; font-size:1.1rem; font-weight:700">Ajout d'un document</h2>
          <label name="file_document" style="font-weight:700">Document</label>
          <b-field
            name="file_document"
            class="file is-primary mt-2"
            :class="{ 'has-name': !!form.file }"
          >
            <b-upload v-model="form.file" class="file-label" name="file_document">
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Téléverser</span>
              </span>
              <span class="file-name" v-if="form.file">
                {{ form.file.name }}
              </span>
            </b-upload>
          </b-field>
          <b-field label="Equipement associé" name="equipment_name">
              <b-select
                id="equipment"
                placeholder="Sélectionner un équipement"
                expanded
                v-model="form.equipment"
              >
                <option
                v-for="(product) in equipments"
                :key="product"
                >
                  {{ product }}
                </option>
              </b-select>
            </b-field>
          <div class="is-flex">
            <div class="ml-auto" >
              <b-button type="is-primary" native-type="submit" :loading="saving">
                Envoyer
              </b-button>
               <b-button class="ml-2" @click="isModalActive = false">
               Annuler
            </b-button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isModalActive: false,
      isDeleteInvoiceModalActive: false,
      form: {
        equipment_name: null,
        file: null,
      },
      saving: false,
    }
  },
  computed: {
    ...mapState({
      client: (state) => state.client,
      equipments: (state) => state.enums.equipments,
    }),
  },
  methods: {
    async submit() {
      this.saving = true
      let toast = {
        message: 'Document ajouté',
        type: 'is-success',
      }

      let send = this.form
      let formData = new FormData()
      Object.entries(send).forEach(([key, value]) => {
        formData.append(key, value)
      })
      send = formData

      if(this.form.file)
        {
        try {
          let { data } = await this.$axios({
            method: 'post',
            url: `/api/clients/${this.$route.params.id}/documents/add`,
            data: send,
          })
        } catch (error) {
          toast = {
            message: 'Erreur',
            type: 'is-failed',
          }
        }
      }

      this.saving = false
      this.$emit('refresh')
      this.isModalActive = false
    },

    openModal()
    {
      this.isModalActive = true
      this.form.equipment = null
      this.form.file = null
    },

    async deleteInvoice(media)
    {
      let send = this.form
      let formData = new FormData()
      Object.entries(send).forEach(([key, value]) => {
        formData.append(key, value)
      })
      this.form.file = media
      send = formData

      try {
        let { data } = await this.$axios({
          method: 'post',
          url: `/api/clients/${this.$route.params.id}/delete/invoice`,
          data: send,
        })
      } catch (error) {
        toast = {
          message: 'Erreur',
          type: 'is-failed',
        }
      }
      this.saving = false
      this.$emit('refresh')
      this.isDeleteInvoiceModalActive = false
    }
  },
}
</script>

<style lang="css" scoped>
.equipments__add {
  padding: 1rem 1rem 0rem 0rem;
  button {
    margin-left: auto;
  }
}
</style>

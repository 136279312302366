<template>
  <div
    class="mt-3"
    style="justify-content: space-between; align-items: flex-start"
    v-if="equipment.status === 'ACTIVE' || equipment.invoicing"
  >
    <div class="card p-3">
      <div>
        <div class="is-flex card-header" style="align-items: center">
          <div class="card-header_title mr-2">Facturation</div>
          <b-button
            v-if="
              this.user.is_super_admin ||
              (this.user.is_admin && this.user.is_user)
            "
            icon-left="pencil-outline"
            @click="edition = !edition"
          ></b-button>
        </div>
        <div v-if="equipment.invoicing">
          <div>Principale : {{ $formatCurrency(equipment.invoicing) }}</div>
        </div>
        <div v-if="equipment.invoicing_subscription">
          <div>Abonnement : {{ $formatCurrency(equipment.invoicing_subscription) }}<span v-if="equipment.invoicing_subscription_method">/{{equipment.invoicing_subscription_method}}</span></div>
        </div>
        <div v-if="!equipment.invoicing && !equipment.invoicing_subscription" class="mb-2">Aucune facturation actuellement.</div>
      </div>
      <base-model-form
        v-if="edition"
        model="clients"
        v-model="form"
        model-name="Client"
        :custom-url="`/api/clients/${client.id}/equipment/${$route.query.equipment}`"
        custom-method="patch"
        :saving.sync="invoicingLoading"
        :replace="replace"
        updated
        style="justify-content: start"
      >
        <b-field label="Facturation" label-for="invoicing">
          <b-input
            id="invoicing"
            v-model="form.invoicing"
            type="number"
            placeholder="0,00"
            step="0.01"
          ></b-input>
        </b-field>
          <div style="display:flex; align-items: center; gap: 10px; margin-bottom: 10px">
            <p style="color: #363636;
                    display: block;
                    font-size: 1rem;
                    font-weight: 700;">Abonnement</p>
            <b-field name="invoicing_subscription_method">
              <b-radio-button
                v-model="form.invoicing_subscription_method"
                native-value="mois"
              >
                /mois
              </b-radio-button>
              <b-radio-button class="ml-2"
                v-model="form.invoicing_subscription_method"
                native-value="an"
              >
                /an
              </b-radio-button>
            </b-field>
          </div>
          <b-field label-for="invoicing_subscription">
          <b-input
            id="invoicing_subscription"
            v-model="form.invoicing_subscription"
            type="number"
            placeholder="0,00"
            step="0.01"
          ></b-input>
        </b-field>
        <b-button
          native-type="submit"
          type="is-primary"
          :loading="invoicingLoading"
          class="mx-auto"
        >
          Valider
        </b-button>
      </base-model-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      savLoading: false,
      cancelLoading: false,
      invoicingLoading: false,
      url: null,
      edition: false,
      form: {
        invoicing: null,
        invoicing_subscription: null,
        invoicing_subscription_method: null,
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      client: (state) => state.client,
      equipment: (state) => state.equipment,
    }),
  },
  mounted() {
    this.edition = false
    if (this.equipment) {
      this.url = `/api/clients/${this.client.id}/equipment/${this.equipment.id}`
      this.form.invoicing = this.equipment.invoicing
    } else {
      this.form.invoicing = null
    }
  },
  methods: {
    ...mapMutations({
      setEquipment: 'setEquipment',
    }),
    async sav() {
      this.savLoading = true
      let { data } = await this.$axios({
        method: 'patch',
        url: this.url,
        data: {
          status: this.$workflow,
        },
      })
      this.savLoading = false
      this.$emit('refresh')
    },
    triggerCancel() {
      this.$buefy.dialog.alert({
        title: 'Résiliation',
        message:
          'Voulez-vous vraiment résilier le contract pour cet équipement ?',
        canCancel: true,
        cancelText: 'Annuler',
        confirmText: 'Valider',
        onConfirm: async () => await this.cancel(),
      })
    },
    async cancel() {
      this.cancelLoading = true
      let { data } = await this.$axios({
        method: 'delete',
        url: this.url,
        data: {
          status: this.$workflow.CANCELED,
        },
      })
      this.setEquipment(data)
      this.cancelLoading = false
      // this.$emit('refresh')
    },
    replace() {
      this.$emit('refresh')
      this.edition = false
    },
  },
}
</script>

<template>
  <div>
    <b-button class="mr-2" @click="isModalActive = true">Nouveau SAV</b-button>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <div class="modal-card-body">
          <h2 class="text-center is-size-4">SAV</h2>
          <SavForm @refresh="close" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SavForm from './form.vue'
export default {
  data() {
    return {
      isModalActive: false,
    }
  },
  methods: {
    close() {
      this.isModalActive = false
      this.$emit('refresh')
    },
  },
  components: { SavForm },
}
</script>

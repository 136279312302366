import Vue from 'vue'
import NProgress from 'vue-nprogress'
import './plugins/sentry'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import './plugins/base'
import './plugins/helpers'
import './assets/scss/app.scss'
import './plugins/svg'

Vue.use(NProgress, {
  latencyThreshold: 100,
})

const nprogress = new NProgress()

Vue.use(Buefy, {
  defaultFirstDayOfWeek: 1,
  defaultDayNames: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  defaultMonthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ],
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  nprogress,
  render: (h) => h(App),
}).$mount('#app')

<template>
  <div class="mt-3">
    <div
      v-if="equipment.status === 'ACTIVE' || equipment.status === 'TO_INSTALL'"
      class="is-flex"
      style="justify-content: end"
    >
      <SavModal />
      <b-button :loading="cancelLoading" @click="triggerCancel">
        Résilier
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SavModal from './modal.vue'

export default {
  data() {
    return {
      savLoading: false,
      cancelLoading: false,
      invoicingLoading: false,
      edition: false,
      form: {
        invoicing: null,
      },
    }
  },
  computed: {
    ...mapState({
      client: (state) => state.client,
      equipment: (state) => state.equipment,
    }),
    fullInvoicing() {
      return (
        this.equipment.invoicing
      )
    },
    url() {
      return this.equipment
        ? `/api/clients/${this.client.id}/equipment/${this.equipment.id}`
        : null
    }
  },
  mounted() {
    if (this.equipment) {
      this.form.invoicing = this.equipment.invoicing
    }
  },
  methods: {
    ...mapMutations({
      setEquipment: 'setEquipment',
    }),
    async sav() {
      this.savLoading = true
      let { data } = await this.$axios({
        method: 'patch',
        url: this.url,
        data: {
          status: this.$workflow,
        },
      })
      this.savLoading = false
      this.$emit('refresh')
    },
    triggerCancel() {
      this.$buefy.dialog.alert({
        title: 'Résiliation',
        message:
          'Voulez-vous vraiment résilier le contract pour cet équipement ?',
        canCancel: true,
        cancelText: 'Annuler',
        confirmText: 'Valider',
        onConfirm: async () => await this.cancel(),
      })
    },
    async cancel() {
      this.cancelLoading = true
      let { data } = await this.$axios({
        method: 'delete',
        url: this.url,
        data: {
          status: this.$workflow.CANCELED,
        },
      })
      this.setEquipment(data)
      this.cancelLoading = false
      // this.$emit('refresh')
    },
    replace() {
      this.$emit('refresh')
      this.edition = false
    },
  },
  components: { SavModal },
}
</script>

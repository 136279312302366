<template>
  <editor
    apiKey="1h193nv0nhtkgi8wi1wuep8rnz2bgtuwhidwspabwimf8ztv"
    :init="init"
    :value="value"
    @input="(value) => $emit('input', value)"
  ></editor>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import trimEnd from 'lodash/trimEnd'

export default {
  components: { Editor },
  props: {
    value: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 500,
    },
    menubar: Boolean,
    inline: Boolean,
    plugins: {
      type: Array,
      default() {
        return [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media paste code help wordcount table',
        ]
      },
    },
    toolbar: {
      type: String,
      default() {
        return 'undo redo | formatselect | bold italic forecolor backcolor | \
            bullist numlist outdent indent | image media table | removeformat | help'
      },
    },
  },
  computed: {
    init() {
      let init = {
        language: 'fr_FR',
        height: this.height,
        menubar: this.menubar,
        plugins: this.plugins,
        toolbar: this.toolbar,
        inline: this.inline,
      }

      /**
       * Image upload with CSRF
       */
      init = {
        ...init,
        paste_data_images: true,
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            let formData = new FormData()
            formData.append('file', blobInfo.blob(), blobInfo.filename())
            let { data } = await this.$axios.post('/api/upload', formData)

            success(data.location)
          } catch (e) {
            failure('HTTP Error: ' + e.status)
          }
        },
      }

      /**
       * File browser config.
       */
      init.file_picker_callback = (callback, value, meta) => {
        window.tinymce.activeEditor.windowManager.openUrl({
          title: 'Gestionnaire de fichier',
          url: `${trimEnd(this.$apiURL, '/')}/elfinder/tinymce5`,
          /**
           * On message will be triggered by the child window
           *
           * @param dialogApi
           * @param details
           * @see https://www.tiny.cloud/docs/ui-components/urldialog/#configurationoptions
           */
          onMessage: function (dialogApi, details) {
            if (details.mceAction === 'fileSelected') {
              const file = details.data.file

              // Make file info
              const info = file.name

              // Provide file and text for the link dialog
              if (meta.filetype === 'file') {
                callback(file.url, { text: info, title: info })
              }

              // Provide image and alt text for the image dialog
              if (meta.filetype === 'image') {
                callback(file.url, { alt: info })
              }

              // Provide alternative source and posted for the media dialog
              if (meta.filetype === 'media') {
                callback(file.url)
              }

              dialogApi.close()
            }
          },
        })
      }

      return init
    },
  },
}
</script>

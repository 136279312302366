<template>
  <div :style="colors"
    class="container is-fluid">
    <h1>{{ $route.meta.title }}</h1>

    <base-data-table model="equipments"
      ref="datatable"
      :columns="columns"
      :filter="filter"
      disable-import
      disable-search
      disable-create
      :clickable="false">
      <template v-slot:filter-client_id="props">
        <base-autocomplete size="is-small"
          v-model="props.filters[props.column.field]"
          model="clients"
          placeholder="Client"
          :text="(model) => model.name">
        </base-autocomplete>
      </template>

      <template v-slot:field-action="props">
        <base-show-button :id="props.row.id"></base-show-button>
      </template>

      <template v-slot:field-status="props">
        <span :class="props.row.status"
          class="tag">
          {{ statuses[props.row.status] }}
        </span>
      </template>

      <template v-slot:field-is_sav="props">
        <base-boolean :value="props.row.is_sav"></base-boolean>
      </template>

      <template v-slot:field-updated_at="props">
        {{ $formatDate(props.row.updated_at, 'dd/MM/yyyy') }}
      </template>

      <template v-slot:field-equipments="props">
        {{ props.row.equipments_readable }}
      </template>

      <template v-slot:field-type="props">
        {{ equipments[props.row.type] }}
      </template>

      <template v-slot:field-client_id="props">
        <span class="tag">
          {{ `${props.row.client.lastname} ${props.row.client.firstname}` }}
        </span>
      </template>

      <template v-slot:row-actions="props">
        <!--
        <b-button
          :id="props.row.id"
          icon-left="magnify"
          tag="router-link"
          :to="{
            name: 'ClientShow',
            params: { id: props.row.client_id },
            query: { equipment: props.row.id },
          }"
          title="Voir"
        >
        </b-button>
        -->
        <a :href="`/clients/${props.row.client_id}?equipment=${props.row.id}`"
          class="button"
          type="button"
          :id="`${props.row.id}`"
          title="Voir">
          <span class="icon is-small"><i class="mdi mdi-magnify"></i></span>
        </a>
        
         <base-delete-button-2 v-if="user.is_admin || user.is_super_admin"
          :id="props.row.id"
          confirm-title="Supprimer un équipement"
          confirm-message="Etes-vous sûr de vouloir supprimer cet équipement ? Cette action est irréversible !"
          success-message="Equipement supprimé avec succès !"
          @deleted="loadAsyncData()"
        />

      </template>
    </base-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      statuses: (state) => state.enums.statuses,
      equipments: (state) => state.enums.equipments,
    }),
    colors() {
      return {
        '--status-new': this.$statusColors.NEW,
        '--status-no_eligible': this.$statusColors.NO_ELIGIBLE,
        '--status-quotation_wait': this.$statusColors.QUOTATION_WAIT,
        '--status-to_install': this.$statusColors.TO_INSTALL,
        '--status-quotation_rejected': this.$statusColors.QUOTATION_REJECTED,
        '--status-installation_abandon': this.$statusColors.ABANDONMENT,
        '--status-install_wip': this.$statusColors.INSTALL_WIP,
        '--status-installed': this.$statusColors.INSTALLED,
        '--status-active': this.$statusColors.ACTIVE,
        '--status-abandonment': this.$statusColors.ABANDONMENT,
        '--status-canceled': this.$statusColors.CANCELED,
      }
    },
    columns() {
      return [
        {
          field: 'updated_at',
          label: 'Date de modification',
          type: 'date',
          searchable: true,
          sortable: true,
        },
        {
          field: 'type',
          label: 'Type',
          type: 'select',
          searchable: true,
          sortable: true,
          options: this.equipments,
        },
        {
          field: 'client_id',
          label: 'Client associé',
          searchable: true,
          sortable: true,
        },
        {
          field: 'status',
          label: 'Statut',
          type: 'select',
          searchable: true,
          sortable: true,
          options: this.statuses,
        },
        {
          field: 'tr_number',
          label: 'N° TR',
          searchable: true,
          sortable: true,
        },
        {
          field: 'is_sav',
          label: 'En SAV',
          searchable: true,
          sortable: true,
          type: 'select',
          options: {
            true: 'Oui',
            false: 'Non',
          },
        },
      ]
    },
    filter() {
      return { ...this.$route.meta.filter, inactive: this.inactive }
    },
  },
  methods: {
    loadAsyncData() {
      this.$refs.datatable.loadAsyncData()
    },
  },
}
</script>

<style scoped>
.NEW {
  background-color: var(--status-new);
  color: white;
}

.NO_ELIGIBLE {
  background-color: var(--status-no_eligible);
  color: white;
}

.QUOTATION_WAIT {
  background-color: var(--status-quotation_wait);
}

.TO_INSTALL {
  background-color: var(--status-to_install);
  color: white;
}

.QUOTATION_REJECTED {
  background-color: var(--status-quotation_rejected);
  color: white;
}

.INSTALLATION_ABANDON {
  background-color: var(--status-installation_abandon);
  color: white;
}

.INSTALL_WIP {
  background-color: var(--status-install_wip);
  color: white;
}

.INSTALLED {
  background-color: var(--status-installed);
}

.ACTIVE {
  background-color: var(--status-active);
  color: white;
}

.ABANDONMENT {
  background-color: var(--status-abandonment);
}

.CANCELED {
  background-color: var(--status-canceled);
  color: white;
}
</style>

<template>
  <div class="px-6">
    <div>
      <div class="title-block">
        <h1 class="title">{{ client.lastname }} {{ client.firstname }}</h1>
        <div class="etiq-block">
          <div class="etiq contract">
            <span class="tag"></span>
            {{ contracts[client.csp] }}
          </div>
        </div>
        <div v-for="equipment in client.equipments"
              :key="equipment.id" class="etiq-block">
          <div v-if="equipment.quotation_tag" class="etiq quotation">
            <span class="tag"></span>
            {{ equipment.quotation_tag }}
          </div>
        </div>
        <div class="etiq-block" v-if="savTag!=undefined">
          <div class="etiq etiq-sav">
            <span class="tag"></span>
            {{ savTag }}
          </div>
        </div>
      </div>
      <div>
        Le
        {{ $formatDate(client.updated_at, 'dd/MM/yyyy') }}
      </div>
      <div v-if="client.is_canceled" class="tag-canceled">Résilié</div>
    </div>
    <div class="columns is-multiline mt-3">
      <div class="column is-3-desktop is-12-tablet">
        <div class="card">
          <div class="card-header">
            <div class="card-header_title">Coordonnées du client</div>
            <div
              v-if="
                this.user.is_super_admin ||
                (this.user.is_admin && this.user.is_user)
              "
              class="card-header_btn"
            >
              <b-button
                icon-left="pencil-outline"
                :to="{
                  name: 'ClientEdit',
                  params: {
                    id: this.$route.params.id,
                  },
                }"
                tag="router-link"
              ></b-button>
            </div>
          </div>
          <ul class="px-5 py-5 client">
            <li>
              <b>{{ client.name }}</b>
            </li>
            <li>
              <i>Adresse : </i>
              <b>{{ client.address }} {{ client.postcode_city }}</b>
            </li>
            <li>
              <i>Téléphone : </i><b>{{ client.phone }}</b>
            </li>
            <li>
              <i>Email : </i><b>{{ client.email }}</b>
            </li>
            <li v-if="client.idgrc!=undefined">
              <i>IDGRC : </i><b>{{ client.idgrc }}</b>
            </li>
            <li v-if="client.client_number">
              <i>Numéro client : </i><b>{{ client.client_number }}</b>
            </li>
            <li v-if="client.entry_site">
              <i>Site : </i><b>{{ client.entry_site }}</b>
            </li>
            <li v-if="client.bringer">
              <i>Apporteur : </i><b>{{ client.bringer.name }}</b>
            </li>
            <li v-if="client.bringer_know">
               <span v-for="(name, value) in bringerKnows"
                  :value="value"
                  :key="value">
                  <span v-if="client.bringer_know == value">
                    <i>Connaissance de l'apporteur : </i><b>{{ name }}</b>
                  </span>
              </span>
            </li>
            <li v-if="client.bringer_know_other">
              <i>Autres : </i><b>{{ client.bringer_know_other }}</b>
            </li>
            <li v-if="client.parrainer_name">
              <i>Nom du parrain : </i><b>{{ client.parrainer_name }}</b>
            </li>
            <li v-if="client.recovery_client">
              <i>Ancien client : </i><b>{{ client.recovery_client }}</b>
            </li>
            <li class="mt-1" v-if="client.comment">
              <i>Commentaire : </i><b>{{ client.comment }}</b>
            </li>
          </ul>
          <div class="card-header">
            <div class="card-header_title">Équipement</div>
          </div>
          <ul class="equipments">
            <li
              v-for="equipment in client.equipments"
              :key="equipment.id"
              :class="$route.query.equipment == equipment.id ? 'current' : ''"
            >
            <div style="display: flex; justify-content: space-between; align-items: center">
              <div>
                <router-link
                  :to="{
                    name: 'ClientShow',
                    params: { id: $route.params.id },
                    query: { equipment: equipment.id },
                  }"
                >
                  {{ equipments[equipment.type] }}
                </router-link>
              
                  <div style="">
                    {{ statuses[equipment.status] }}
                    {{
                      $formatDate(
                        equipment.status_updated_at[equipment.status],
                        'dd/MM/yyyy'
                      )
                    }}
                </div>
              </div>
              <DeleteEquipment :id="equipment.id" @refresh="refreshModel"/>
            </div>
              <span v-if="equipment.is_sav">SAV</span>
            </li>
          </ul>
          <AddEquipmentModal
            v-if="
              (this.user.is_super_admin ||
                (this.user.is_admin && this.user.is_user)) &&
              !client.is_canceled
            "
            @refresh="refreshModel"
          />
          <div>
            <div class="card-header">
              <div class="card-header_title">Résiliation</div>
            </div>
            <ClientCancel
              v-if="
                this.user.is_super_admin ||
                (this.user.is_admin && this.user.is_user)
              "
              @refresh="refreshModel"
            />
          </div>
        </div>
      </div>
      <div
        class="column is-12-tablet is-9-desktop is-relative"
      >
        <div v-if="client.is_canceled" class="canceled"></div>
        <div v-if="client.equipments.length" class="tabs">
          <button style="font-size: 1rem; color: #363636;" @click="currentTab = 'tab1'" :class="{ active: currentTab === 'tab1' }" v-if="equipment">{{ equipments[equipment.type] }}</button>
          <button style="font-size: 1rem; color: #363636;" @click="currentTab = 'tab1'" :class="{ active: currentTab === 'tab1' }" v-else>Equipement</button>

          <button style="font-size: 1rem; color: #363636;" @click="currentTab = 'tab2'" :class="{ active: currentTab === 'tab2' }">Documents</button>
          <button style="font-size: 1rem; color: #363636;" v-if="this.user.is_super_admin || this.user.is_admin" @click="currentTab = 'tab3'" :class="{ active: currentTab === 'tab3' }">Commentaires</button>
        </div>
        
        <div v-if="
          equipment &&
          Object.keys(equipment).length !== 0 &&
          Object.getPrototypeOf(equipment) === Object.prototype
        ">
          <StatusWorkflow v-if="currentTab === 'tab1'" @refresh="refreshModel"/>

          <div v-if="currentTab === 'tab1'">
            <div class="workflow-cards mt-2">
              <EligiblityForm
                @refresh="refreshModel"
                class="workflow-cards__card"
              />
              <QuotationForm @refresh="refreshModel" class="workflow-cards__card" />
              <InstallationForm
                @refresh="refreshModel"
                class="workflow-cards__card"
              />
            </div>
            <SavBlock @refresh="refreshModel" />
            <div class="invoicing-sav_items">
              <Invoicing class="invoicing-sav_items-small" />
              <SavList
                :sav-items="equipment.sav_items"
                @refresh="refreshModel"
                class="invoicing-sav_items-large"
              />
            </div>
          </div>

          <div v-if="currentTab === 'tab2'">
            <div>
              <DocumentBlock @refresh="refreshModel"/>
            </div>
          </div>

          <div v-if="currentTab === 'tab3'">
            <div>
              <CommentBlock v-if="this.user.is_super_admin || this.user.is_admin" @refresh="refreshModel"/>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import AddEquipmentModal from '@/components/clients/add-equipment-modal.vue'
import DeleteEquipment from '@/components/clients/delete-equipment.vue'
import EligiblityForm from '@/components/clients/eligiblity-form.vue'
import StatusWorkflow from '../../components/clients/status-workflow.vue'
import QuotationForm from '../../components/clients/quotation-form.vue'
import InstallationForm from '@/components/clients/installation-form.vue'
import SavList from '@/components/clients/sav-item/list.vue'
import SavBlock from '../../components/clients/sav-item/block.vue'
import ClientCancel from '@/components/clients/client-cancel.vue'
import Invoicing from '@/components/clients/invoicing.vue'
import DocumentBlock from '@/components/clients/documents/block.vue'
import CommentBlock from '@/components/clients/comments/block.vue'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      statuses: (state) => state.enums.statuses,
      workflow: (state) => state.enums.workflow,
      equipments: (state) => state.enums.equipments,
      contracts: (state) => state.enums.contracts,
      refresh: (state) => state.refresh,
      equipment: (state) => state.equipment,
      client: (state) => state.client,
      bringerKnows: (state) => state.enums.bringerKnows
    }),
    /**
     * Donne le texte de l'étiquette SAV.
     * @return Texte de l'étiquette au format "SAV niveau X en cours"
     */
    savTag() {
      let equipments = this.$route.meta.model.equipments
      let savMax = null;
      for(let i=0; i<equipments.length; i++) {
        let equipment = equipments[i];
        if ( !equipment.is_sav ) continue;
        // SAV
        for(let j=0; j<equipment.sav_items.length; j++) {
          let savItem = equipment.sav_items[j];
          if ( savItem.level>1 ) {
            if ( savMax==undefined || (savMax!=undefined && savItem.level>savMax.level) ) {
              savMax = savItem;
            }
            break;
          }
        }
      }
      return savMax==undefined ? null : `SAV niveau ${savMax.level} en cours`;
    }
  },
  data() {
      return {
        currentTab: 'tab1',
        reloadOtherEquipment: false,
      };
    },
  watch: {
    '$route.query.equipment'(newValue) {
      this.refreshModel()
    },
    refresh(newValue) {
      this.refreshModel()
    },
  },
  created() {
    this.setClient(this.getClient())
    this.setEquipment(this.getEquipment())
    this.redirect()
  },
  methods: {
    ...mapMutations({
      toggleRefresh: 'toggleRefresh',
      setClient: 'setClient',
      setEquipment: 'setEquipment',
    }),
    redirect() {
      if (this.$route.query.equipment === undefined) {
        let equipments = this.$route.meta.model.equipments
        if (equipments.length) {
          let equipment = equipments[0]
          this.$router.replace({
            query: { equipment: equipment.id },
          })
        }
      }
    },
    reload() {
      this.refreshModel()
    },
    getClient() {
      return this.$route.meta.model
    },
    getEquipment() {
      let equipment = {}
      if (this.$route.query.equipment) {
        equipment = this.client.equipments.find(
          (e) => e.id == this.$route.query.equipment
        )
      } else if (this.client.equipments.length > 0) {
        equipment = this.client.equipments[0]
      }

      return equipment
    },
    async refreshModel() {
      let { data } = await this.$axios({
        url: this.$route.meta.url,
      })
      this.setClient(data)
      this.setEquipment(this.getEquipment())
    },
  },
  components: {
    AddEquipmentModal,
    DeleteEquipment,
    EligiblityForm,
    StatusWorkflow,
    QuotationForm,
    InstallationForm,
    SavList,
    SavBlock,
    ClientCancel,
    Invoicing,
    DocumentBlock,
    CommentBlock,
  },
}
</script>

<style lang="scss" scoped>
.tag-canceled {
  font-size: 1.6rem;
  color: red;
  font-weight: 700;
}
.canceled {
  background-color: white;
  opacity: 0.6;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.workflow-cards {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  @include desktop {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.invoicing-sav_items {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  .invoicing-sav_items-small {
    grid-column: span 1 / span 1;
  }
  .invoicing-sav_items-large {
    grid-column: span 2 / span 2;
  }
}
.title-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .title {
    margin-bottom: 0;
  }
  .etiq-block {
    .etiq {
      position: relative;
      background-color: black;
      color: white;
      width: max-content;
      padding: 0.25rem 1.25rem;
      border-radius: 0.25rem;
      font-size: 0.8rem;
      margin: 0.25rem 0 0 1.5rem;
      .tag {
        position: absolute;
        left: -1rem;
        background-color: black;
        clip-path: polygon(100% 25%, 50% 50%, 100% 75%);
      }
      &.contract {
        background-color: $gray;
        .tag {
          background-color: $gray;
        }
      }
      &.quotation {
        background-color: $orange;
        .tag {
          background-color: $orange;
        }
      }
      &.etiq-sav {
        background-color: $red;
        .tag {
          background-color: $red;
        }
      }
    }
  }
}
/deep/ .card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  box-shadow: unset;
  .card-header_title {
    text-align: center;
  }
  .card-header_btn {
    margin-left: 1rem;
  }
}
h2 {
  font-size: 1.4rem;
  color: $gray-dark;
}
.client {
  color: $gray;
}
.equipments {
  li {
    border-top: $gray-soft solid 1px;
    padding: 0.5rem 2rem;
    a {
      text-transform: uppercase;
      width: 100%;
      display: block;
      &:hover {
        color: $red-tlb;
      }
    }
  }
  li:last-child {
    border-bottom: $gray-soft solid 1px;
  }
  .current {
    font-weight: 600;
    a {
      color: $red-tlb;
    }
  }
}
/deep/ .title-card {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}
.active {
  background-color:  $red-tlb !important;
  color: white !important;
}

/* Style pour les boutons */
.tabs {
  display: flex;
  margin-bottom: 0px !important
}

.tabs button {
  flex: 1;
  border: none;
  padding: 10px;
  background-color: #f1f1f1;
  cursor: pointer;
  border: 1px solid rgb(179, 175, 175);
  border-radius: 5px;
  font-weight: bold; 
}

.tabs button:not(:last-child) {
  margin-right: 5px;
}
</style>

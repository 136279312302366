<template>
  <div class="container is-fluid">
    <div class="colonnes">
      <div class="colonne colonne-carte">
        <div class="sav-equipments">{{ sav }} Équipements en SAV</div>
        <div class="colonnes-sav">
          <div class="colonne colonne-carte">
            <div class="sav-equipments-level"><a href='clients/?perPage=20&page=1&filter=%7B"sav"%3A1%7D'>{{ sav_level_2 }} SAV de niveau 2 ></a></div>
          </div>
          <div class="colonne colonne-carte">
            <div class="sav-equipments-level"><a href='clients/?perPage=20&page=1&filter=%7B"sav"%3A1%7D'>{{ sav_level_3 }} SAV de niveau 3 ></a></div>
          </div>
        </div>
        <stats-card-doughnut
          class="stats"
          :chart-data="statusChartData"
          :legend-color="$statusColors"
          label="Équipements"
        ></stats-card-doughnut>
      </div>
      <div v-if="!user.is_commercial" class="colonne colonne-client">
        <div class="card">
          <h2 class="title titre-client">Créer un client</h2>
          <client-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import qs from 'qs'
import ClientForm from './clients/Form.vue'
import StatsCardDoughnut from "@/components/cards/StatsCardDoughnut";

export default {
  components: {
    StatsCardDoughnut,
    ClientForm,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      statuses: (state) => state.enums.statuses,
    }),
  },
  data: () => ({
    statusChartData: {},
    savChartData: {},
    sav: 0,
    sav_level_2: 0,
    sav_level_3: 0,
  }),
  async created() {
    if(this.user.is_user)
    {
      location.href = '/clients'
    }
    /**
     * Le résultat est également groupé par domaine
     */
    let filter = {}
    if (this.user.is_bringer) {
      filter = {
        ...filter,
        bringer_id: this.user.id,
      }
    }
    let { data } = await this.$axios.get(
      `/api/statistics/equipments?${qs.stringify({
        filter,
      })}`
    )
    this.statusChartData = data.statuses || {}
    this.savChartData = data.sav || {}
    if (data.sav) {
      this.sav = data.sav.counters[1]
      this.sav_level_2 = data.sav.level_2
      this.sav_level_3 = data.sav.level_3
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  color: white !important;
}
.title {
  text-align: center;
}
.colonnes {
  display: flex;
  margin: -0.75rem;
  > div {
    padding: 0.75rem;
    // min-width: 500px;
    width: 100%;
    &.colonne-carte {
      > .stats {
      }
    }
    .titre-client {
      padding-top: 0.75rem;
    }
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

 @media (min-width: 1200px) {
  .colonnes-sav {
    display: flex;
    margin: -0.75rem;
    > div {
      padding: 0.75rem;
      // min-width: 500px;
      width: 100%;
      &.colonne-carte {
        > .stats {
        }
      }
      .titre-client {
        padding-top: 0.75rem;
      }
    }
  }
}
.sav-equipments {
  background-color: $red-tlb;
  padding: 1rem;
  color: white;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.sav-equipments-level {
  background-color: $red-tlb;
  padding: 1rem;
  color: white;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .sav-equipments-level {
    margin-bottom: 1rem;
  }
}
</style>
